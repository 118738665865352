<template>
  <div>
    <div class="orderList">
      <el-backtop target=".orderList"> </el-backtop>
      <my-header :position="'relative'"></my-header>
      <el-container>
        <el-main id="el-main">
          <div class="order-title">
            <span>我的订单</span>
          </div>
          <div class="order-nav">
            <el-tabs
              :stretch="true"
              class="order-nav-top"
              v-model="activeName"
              @tab-click="tabClick"
            >
              <el-tab-pane label="全部订单" name="">
                <order-list
                  v-if="orderList.length"
                  @getOrderList="getOrderList"
                  :OrderList="orderList"
                  :total="total"
                ></order-list>
                <div class="none" v-else>没有订单</div>
              </el-tab-pane>
              <el-tab-pane label="待付款" name="10" >
                <order-list
                  v-if="unpaidOrderList.length"
                  @unpaidOrderList="chakandaizhifu"
                  :OrderList="unpaidOrderList"
                  :total="daitotal"
                ></order-list>
                <div class="none" v-else>没有订单</div>
              </el-tab-pane>
              <el-tab-pane label="待收货" name="20">
                <order-list-son
                  v-if="activeName == 20 && shippedOrderLists"
                  @getOrderListSon="getOrderListSon"
                  :OrderListSon="OrderListSon"
                  :total="yitotal"
                ></order-list-son>
                <div class="none" v-else>没有订单</div>
              </el-tab-pane>
              <el-tab-pane label="待发货" name="40">
                <order-list-son
                  v-if="activeName == 40 && shippedOrderList"
                  @getOrderListSon="getOrderListSon"
                  :OrderListSon="OrderListSon"
                  :total="total"
                ></order-list-son>
                <div class="none" v-else>没有订单</div>
              </el-tab-pane>
              <el-tab-pane label="已完成" name="50">
                <order-list-son
                  v-if="activeName == 50 && OrderListSonSwitch"
                  @getOrderListSon="getOrderListSon"
                  :OrderListSon="OrderListSon"
                  :total="total"
                ></order-list-son>
                <div class="none" v-else>没有订单</div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import orderList from "../components/orderList/orderList";
import orderListSon from "../components/orderList/orderListSon";
export default {
  components: { orderList, orderListSon },
  data() {
    return {
      // 设置请求到数据之后才显示的开关
      OrderListSonSwitch: false,
      shippedOrderList:false,
      shippedOrderLists:false,
      // 当前选中状态
      activeName: "",
      name: "",
      // 用户信息
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      userId: "",
      // 已完成订单
      // 已取消订单
      // 已发货订单
      OrderListSon: [],
      // 已支付的订单
      prepaidOrderList: [],
      // 未支付订单
      unpaidOrderList: [],
      // 所有订单
      orderList: [],
      // 总条数
      total: 0,
      daitotal:0,
      yitotal:0,
      state:''  //订单状态
    };
  },
  watch: {
    activeName(newVal) {
      if (newVal == 20  ||newVal == 40  || newVal == 50) {
        this.getOrderListSon();
      }
    },
  },
  methods: {
    //查看已支付订单
    async chakanyizhifu(pageNum = 1, pageSize = 5){
      // let userId = sessionStorage.getItem("userId");
      const res = await this.$http({
        method: "get",
        url: "front/order/selectAllOrder",
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          sortType: "create_time desc",
          state:20,
          type:'1',
        },
      });
      if (res.data.data) {
        this.yitotal = res.data.data.total;
        this.prepaidOrderList = res.data.data.list;
      }
    },
    //查看待支付订单
    async chakandaizhifu(pageNum = 1, pageSize = 5){
      // let userId = sessionStorage.getItem("userId");
      const res = await this.$http({
        method: "get",
        url: "front/order/selectAllOrder",
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          sortType: "create_time desc",
          state:10,
          type:'1',
        },
      });
      if (res.data.data) {
        this.daitotal = res.data.data.total;
        this.unpaidOrderList = res.data.data.list;
      }
    },
    // 查询所有订单
    async getOrderList(pageNum = 1, pageSize = 5) {
      console.log(pageNum);
      // let userId = sessionStorage.getItem("userId");
      const res = await this.$http({
        method: "get",
        url: "front/order/selectAllOrder",
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          sortType: "create_time desc",
          state:'',
          type:'1',
        },
      });
      // console.log(res)
      if (res.data.data) {
        this.total = res.data.data.total;
        this.orderList = res.data.data.list;
        // this.unpaidOrderList = [];
        // this.prepaidOrderList = [];
        // console.log(res.data.data.list+'suoyoudingdan ');
        this.orderList.forEach((v, k) => {
        //   if (v.state == 10) {
        //     this.unpaidOrderList.push(v);
        //     console.log(this.unpaidOrderList+"待支付订单")
        //   }
        //   if (v.state == 20) {
        //     this.prepaidOrderList.push(v);
        //     console.log(this.prepaidOrderList);
        //   }
        if (v.state == 20) {
            this.shippedOrderLists.push(v);
            console.log(this.shippedOrderList);
          }
          if (v.state == 40) {
            this.shippedOrderList.push(v);
            console.log(this.shippedOrderList);
          }
          if (v.state == 50) {
            this.doneOrderList.push(v);
            console.log(this.doneOrderList);
          }
        });
      }
    },
    // 获取所有子订单
    async getOrderListSon(pageNum = 1, pageSize = 5) {
      console.log(pageNum);
      let userId = sessionStorage.getItem("userId");
      const res = await this.$http({
        method: "get",
        url: "back/orderItem/findAll",
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          sortType: "create_time desc",
          status: this.activeName,
          sellerUserId: userId,
          type:'1',
        },
      });
      if (res.data.data.list != '') {
        this.total = res.data.data.total;
        this.OrderListSon = res.data.data.list;
        console.log(this.OrderListSon);
        setTimeout(() => {
          this.OrderListSonSwitch = true;
          this.shippedOrderList = true;
          this.shippedOrderLists = true;
        }, 50);
      }else {
          this.OrderListSonSwitch = false;
          this.shippedOrderList = false;
          this.shippedOrderLists = false;
      }
    },
    //修改当前选中状态
    updateActive(index) {
      this.activeName = index;
    },
    // 修改total值
    updateTotal(num) {
      this.total = num;
    },
    // tab被选中
    tabClick(index) {
      console.log(index.label);
      console.log(this.orderList)
      // if (index.label == "待付款") {
      //   this.total = this.unpaidOrderList.length;
      // } else if (index.label == "已支付") {
      //   this.total = this.prepaidOrderList.length;
      // }
    },
    async getGoodShop() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList?type=1",
      });
      if (res.data.data) {
        this.$store.commit(
          "altershopnum",
          res.data.data.cartProductVoList.length
        );
      }
    },
  },
  created() {
    this.getOrderList();
    this.chakandaizhifu();
    this.chakanyizhifu();
    this.getGoodShop();
  },
};
</script>
<style lang='scss' scoped>
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.orderList {
  width: 100%;
  /*核心  */

  /* 核心 */
  overflow: hidden;
}
/* .toTop {
  padding: 10px;
  box-shadow: 0px 0px 5px black;
} */
/* .el-container {
  height: 1000px;
} */

.shopcar-img {
  width: 28px;
  height: 28px;
  margin-top: 2px;
  margin-left: 3px;
}
.shop-car p {
  display: block;
  font-size: 14px;
  line-height: 35px;
  margin-left: 10px;
  overflow: hidden;
}
.shop-car div {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: rgb(255, 145, 0);
  margin-top: 7px;
  margin-left: 3px;
}
.shop-cara p {
  font-size: 14px;
  margin: auto;
  text-align: center;
  line-height: 20px;
  font-weight: 800;
  color: white;
}
#el-main {
  width: 100%;
  background: #f5f5f5;
  overflow: visible;
}
.order-title {
  width: 1200px;
  height: 60px;
  background: white;
  margin: auto;
}
.order-title span {
  line-height: 60px;
  font-size: 14px;
  padding-left: 10px;
}
.order-nav {
  display: flex;
  width: 1200px;
  background: white;
  margin: auto;
  margin-top: 30px;
}
.order-nav-top {
  width: 100%;
}
.el-row {
  color: #666;
}
.el-row > div:not(:nth-child(1)) {
  margin-top: 20px;
}
.el-row-box > div {
  margin-top: 0px !important ;
  line-height: 50px;
}
.el-col > div {
  color: #666;
}
div.el-card__body {
  padding: 8px 30px;
}
.el-collapse-item__header {
  padding-left: 40px;
  color: deepskyblue;
}
.el-button > span {
  color: #fff;
}

.none {
  height: 100px;
  text-align: center;
  padding: 100px;
}
.el-icon-circle-close:before {
  display: inline-block;
  color: #fff;
  width: 20px;
  font-size: 30px;
  position: absolute;
  left: 150px;
}
</style>
